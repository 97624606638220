import { Fragment, useContext } from "react";
import ReactDOM from "react-dom";
import { ModalContext } from "../context/modalContext";
import "./modal.css";

const Backdrop = () => {
  const { hideBanner } = useContext(ModalContext);
  return <div className="backdrop" onClick={hideBanner} />;
};

const portalElement = document.getElementById("overlays");

const Modal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(<Backdrop />, portalElement)}
      {ReactDOM.createPortal(
        <div className="modal">{props.children}</div>,
        portalElement
      )}
    </Fragment>
  );
};

export default Modal;
