import "./App.css";
import { FaChevronRight, FaCircle, FaWhatsapp } from "react-icons/fa";
import { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { ModalContext } from "./context/modalContext";
import Form from "./modal/Form";
import { ToastContainer } from "react-toastify";

const App = () => {
  const { openModal, showBanner } = useContext(ModalContext);
  const [cursorVariant, setCursorVariant] = useState("default");
  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    const mouseMove = (e) => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY,
      });
    };
    window.addEventListener("mousemove", mouseMove);
  });

  const variants = {
    default: {
      opacity: 1,
      color: "#00853e",
      placeItems: "center",
      fontSize: "8px",
      display: "flex",
      backgroundColor: "#ffffff00",
      x: mousePosition.x - 16,
      y: mousePosition.y - 16,
      transition: {
        type: "spring",
        mass: 0.6,
      },
    },
    link: {
      placeItems: "center",
      display: "flex",
      color: "#00853ebf",
      fontSize: "24px",
      x: mousePosition.x - 16,
      y: mousePosition.y - 16,
      transition: {
        type: "spring",
        mass: 0.6,
      },
    },
  };
  const linkEnter = () => setCursorVariant("link");
  const linkLeave = () => setCursorVariant("default");

  return (
    <div className="app">
      <ToastContainer />
      {openModal && <Form />}
      <a
        href="https://wa.me/+971544234144"
        className="sticky-whatsapp"
        onMouseEnter={linkEnter}
        onMouseLeave={linkLeave}
      >
        <FaWhatsapp className="sticky-whatsapp-icon" />
      </a>

      <motion.div
        className="cursor"
        variants={variants}
        animate={cursorVariant}
      >
        <FaCircle />
      </motion.div>
      <div className="video">
        <div className="overlay"></div>
        <video autoPlay loop muted playsInline>
          <source src="./images/video.mp4" type="video/mp4" />
        </video>
      </div>

      <div className="logo">
        <img src="/images/logo.png" alt="uenergy solar panels" />
      </div>
      <div className="content">
        <p>UK based company</p>
        <div className="logo-heading">
          <img src="/images/logo.png" alt="uenergy solar panels" />
        </div>

        <h2>
          transforming energy for the 21<sup>st</sup> century
        </h2>

        <p className="launch">launching soon</p>
        <div className="img">
          <img src="/images/uae.png" alt="uenergy launching soon in UAE" />
        </div>

        <div className="row2">
          <a href="https://www.uenergysolar.co.uk/">
            <button
              className="btn"
              onMouseEnter={linkEnter}
              onMouseLeave={linkLeave}
            >
              UK Website <FaChevronRight className="chevron" />
            </button>
          </a>

          <a href="https://www.google.co.uk/search?q=u%20energy%20huddersfield&tbm=isch&tbs=rimg:CQnkUzNoJZ2uYcU4FC8taGi78AEBsgIIEAA6BAgAEAA&hl=en&sa=X&ved=0CBwQuIIBahcKEwj468jhrIP9AhUAAAAAHQAAAAAQDg&biw=1494&bih=706&dpr=1.25">
            <button
              className="btn"
              onMouseEnter={linkEnter}
              onMouseLeave={linkLeave}
            >
              our projects <FaChevronRight className="chevron" />
            </button>
          </a>
          <button
            className="btn"
            onMouseEnter={linkEnter}
            onMouseLeave={linkLeave}
            onClick={showBanner}
          >
            contact us <FaChevronRight className="chevron" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default App;
