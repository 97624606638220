import { createContext, useState } from "react";

export const ModalContext = createContext();

export const ModalContextProvider = ({ children }) => {
  const [openModal, setOpenModal] = useState(false);
  const showBanner = () => {
    setOpenModal(true);
  };

  const hideBanner = () => {
    setOpenModal(false);
  };

  return (
    <ModalContext.Provider
      value={{ openModal,setOpenModal, showBanner, hideBanner }}
    >
      {children}
    </ModalContext.Provider>
  );
};
