import "./modal.css";
import Modal from "./Modal";
import { AiOutlineClose } from "react-icons/ai";

import { useContext, useRef } from "react";
import { ModalContext } from "../context/modalContext";
import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import { toast, Slide } from "react-toastify";
import emailjs from "@emailjs/browser";

const Form = () => {
  const { setOpenModal, hideBanner } = useContext(ModalContext);
  const form = useRef();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  // toastify data
  const toastOptions = {
    position: "top-center",
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Slide,
    bodyClassName: "toast-font-size",
  };

  // Function called on submit that uses emailjs to send email of valid contact form
  const onSubmit = async () => {
    try {
      await emailjs
        .sendForm(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          form.current,
          process.env.REACT_APP_USER_ID
        )
        .then(
          (result) => {
            // console.log(result);
            toast(
              "Thank you for contacting Uenergy. We will get back to you shortly.",
              toastOptions
            );
            reset();
            setOpenModal(false);
          },
          (error) => {
            toast(
              "Sorry, There's an error occured. Try again later ",
              toastOptions
            );
          }
        );
    } catch (e) {
      // console.log(e);
    }
  };
  return (
    <>
      <Modal>
        <div className="modal-header">
          <img
            src="images/logo.png"
            alt="syncom-logo"
            className="navbar-logo-image"
          />
          <AiOutlineClose className="closeModal" onClick={hideBanner} />
        </div>

        <form className="form" onSubmit={handleSubmit(onSubmit)} ref={form}>
          <div className="input-group">
            <input
              type="text"
              name="name"
              {...register("name", {
                required: {
                  value: true,
                  message: "Please enter your name",
                },
                maxLength: {
                  value: 30,
                  message: "Please use 30 characters or less",
                },
              })}
              placeholder="full name"
            />

            {errors.name && (
              <span className="errorMessage">{errors.name.message}</span>
            )}
          </div>

          <div className="input-group">
            <input
              type="email"
              name="email"
              {...register("email", {
                required: true,
                pattern:
                  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              })}
              placeholder="email address"
            />

            {errors.email && (
              <span className="errorMessage">enter valid email address</span>
            )}
          </div>
          <div className="input-group">
            <input
              type="tel"
              name="phone"
              {...register("phone", {
                required: {
                  value: true,
                  message: "Please enter your valid mobile number",
                },
              })}
              placeholder="mobile number"
            />

            {errors.phone && (
              <span className="errorMessage"> {errors.phone.message}</span>
            )}
          </div>
          <div className="input-group">
            <textarea
              rows={3}
              name="message"
              {...register("message", {
                required: true,
              })}
              className="form-control formInput"
              placeholder="message"
            ></textarea>

            {errors.message && (
              <span className="errorMessage">Please enter some message</span>
            )}
          </div>

          <button className="btn" type="submit">
            send request
          </button>
        </form>
      </Modal>
    </>
  );
};

export default Form;
